import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/zasady_pouzivania_suborov_cookies/" className="sel">
              {" "}
              &gt; Zásady používania súborov cookies
            </a>
          </div>
          <div id="page_content">
            <h1 className="uc">Zásady používania súborov cookies</h1>
            <div className="wysiwyg">
              <div>
                <p>
                  Tieto zásady používania súborov cookie obsahujú informácie o našich postupoch týkajúcich sa zhromažďovania údajov prostredníctvom súborov cookie alebo iných monitorovacích technológií (napríklad súborov gif, tzv. webových signálov, atď.).
                </p>
                <p>
                  Tieto technológie môžeme používať napríklad pre nižšie uvedené funkcie:
                </p>
                <ul>
                  <li>
                    pomoc pri vašej navigácii;
                  </li>
                  <li>pomoc pri registrácii na naše akcie, pri prihlásení sa a umožnenie získania spätnej väzby od vás</li>
                  <li>analýza používania našich produktov, služieb či aplikácií;</li>
                  <li>pomoc pri našej podpore predaja a marketingu (vrátane tzv. behaviorálnej reklamy, teda cielenej reklamy podľa správania používateľov);</li>
                  <li>ponuka obsahu tretích strán (napríklad obsahu sociálnych médií).</li>
                </ul>
                <p>
                  Nižšie uvádzame zoznam nami používaných súborov cookie s ich opisom. Súbory cookie zaraďujeme do týchto kategórií:
                </p>
                <ul>
                  <li>
                    Nevyhnutne potrebné súbory cookie
                  </li>
                  <li>Analytické súbory cookie</li>
                  <li>Súbory cookie pre zabezpečenie funkčnosti</li>
                  <li>Reklamné súbory cookie</li>
                </ul>
                <p>
                  Pokiaľ nám platné právne predpisy neumožňujú inak, súbory cookie umiestňujeme až po získaní vášho súhlasu prostredníctvom banneru upozorňujúceho na zásady používania súborov cookie či prostredníctvom centra správy vašich nastavení. Svoje nastavenia ohľadne súborov cookie pre každú ich jednotlivú kategóriu (okrem kategórie nevyhnutne potrebných súborov cookie, ktoré sú nutné na riadne fungovanie webových stránok) môžete kedykoľvek zmeniť kliknutím na tlačidlo “nastavenia súborov cookie” nižšie:
                </p>

                <button id="ot-sdk-btn" className="ot-sdk-show-settings">Cookie Nastavenie</button>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <div id="ot-sdk-cookie-policy" />


                <p><strong>Odkazy na iné stránky</strong></p>
                <p>
                  Táto stránka môže obsahovať odkazy alebo referencie na iné webové stránky. Majte, prosím, na pamäti, že súbory cookie / monitorovacie technológie iných webových stránok neriadime a tieto zásady používania súborov cookie sa na také stránky nevzťahujú.
                </p>
                <p>
                  <strong>Ako nás kontaktovať</strong>
                </p>
                <p>
                  Pokiaľ máte akékoľvek otázky, poznámky alebo pripomienky ohľadne týchto zásad používania súborov cookie alebo postupov týkajúcich sa informácií na týchto stránkach, použite, prosím, kontaktné údaje uvedené v našich zásadách o ochrane súkromia.
                </p>
                <p>
                  <strong>Zmeny v týchto zásadách používania súborov cookie</strong>
                </p>
                <p>
                  Pokiaľ sa tieto zásady používania súborov cookie zmenia, bude ich aktualizácia zverejnená na týchto stránkach. Tieto zásady používania súborov cookie boli naposledy aktualizované 12-05-2021.
                </p>
              </div>
              <p>&nbsp;</p>
            </div>
          </div>
          <Footer></Footer>{" "}
        </div>
      </div>
    );
  }
}

export default Page;
